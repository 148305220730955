import { createContext } from "react";

export type FeatureFlagsModel = {
    featureFlag : {
        featureFlag_HcpApplication_SkillsChecklist_IsEnabled: boolean,
        featureFlag_DashboardOffersAndSubmissions_IsEnabled?: boolean,
        featureFlag_DocumentsAndSignatures_IsEnabled: boolean
        featureFlag_ReferAFriend_IsEnabled: boolean,
        featureFlag_UpdateBackgroundQuestions_IsEnabled: boolean,
        featureFlag_CredentialsDropbox_IsEnabled: boolean
    }
}

const defaultValue: FeatureFlagsModel = {
    featureFlag: {
        featureFlag_HcpApplication_SkillsChecklist_IsEnabled: false,
        featureFlag_DashboardOffersAndSubmissions_IsEnabled: undefined,
        featureFlag_DocumentsAndSignatures_IsEnabled: false,
        featureFlag_ReferAFriend_IsEnabled: false,
        featureFlag_CredentialsDropbox_IsEnabled: false,
        featureFlag_UpdateBackgroundQuestions_IsEnabled: false
    }
}

const FeatureFlagContext = createContext(defaultValue);

export default FeatureFlagContext;