import { GetHcpDocumentsAndSignaturesResponse } from "../tp-core-types/GetHcpDocumentsAndSignaturesResponse"
import displayAsDateAndTime from "../utilities/displayAsDateAndTime";
import  pdf_icon  from "../../src/assets/pdf_icon.png"
import "./EDocumentsAndSignatures.scss"

type Props = {
    documents: GetHcpDocumentsAndSignaturesResponse;
    handleGetContract: (contractNum: number, documentMasterId: number) => void;
    openFile: (contractNum: number, documentMasterId: number) => void;
}

export default function EDocumentsAndSignaturesDesktop(props: Props) {
    return (
        <>
        <div className="document-display-section">
                            <div className="sub-title">Contract Documents Waiting to be Signed</div>
                            <div className="all-documents-container">
                                {props.documents.signableDocuments.length === 0 
                                    ? <div className="document-container" data-testid="no-signable-documents">
                                        <div>No documents to display</div>
                                      </div> 
                                    : props.documents.signableDocuments.map(document => {
                                            return (
                                                <div className="signable-document-container" key={document.contractNum + document.documentMasterId}>
                                                <div>
                                                    <div className="bold">{document.documentTitle}</div>
                                                    <div>{`${document.profession} (${document.specialty}) - ${document.facilityName}, ${document.city}, ${document.state}`}</div>
                                                </div>
                                                <button className="sign-document-button" onClick={() => {props.handleGetContract(document.contractNum, document.documentMasterId)}}>Review & Sign Document</button>
                                            </div>)
                                    })}
                            </div>
                        </div>
                        <div className="document-display-section">
                            <div className="sub-title"> Signed Contract Documents for Your Current and Future Assignments </div>
                            <div className="all-documents-container">
                                {props.documents.signedDocumentsForCurrentAssignment && props.documents.signedDocumentsForCurrentAssignment.length === 0
                                    ? <div className="document-container" data-testid="no-current-documents">
                                        <div>No documents to display</div>
                                      </div> 
                                    : props.documents.signedDocumentsForCurrentAssignment.map(document => (
                                    <div className="document-container clickable" data-testid="clickable-document-container" onClick={() => props.openFile(document.contractNum, document.documentMasterId)} key={document.contractNum + document.documentMasterId}>
                                        <img src={pdf_icon} alt="document" className="pdf-icon"></img>
                                        <div>{`${document.documentTitle} - ${document.facilityName} - ${displayAsDateAndTime(document.dateTimeSigned).toString().replaceAll(',', '')} CT`}</div>
                                    </div>  
                                ))}
                            </div>
                        </div>
                        <div className="document-display-section">
                            <div className="sub-title"> Signed Contract Documents from Previous Assignments</div>
                            <div className="all-documents-container">
                                {props.documents.signedDocumentsForPastAssignments.length === 0
                                    ? <div className="document-container" data-testid="no-past-documents">
                                        <div>No documents to display</div>
                                      </div> 
                                    : props.documents.signedDocumentsForPastAssignments.map(document => (
                                    <div className="document-container clickable" data-testid="clickable-document-container" onClick={() => props.openFile(document.contractNum, document.documentMasterId)} key={document.contractNum + document.documentMasterId}>
                                        <img src={pdf_icon} alt="document" className="pdf-icon"></img>
                                        <div>{`${document.documentTitle} - ${document.facilityName} - ${displayAsDateAndTime(document.dateTimeSigned).toString().replaceAll(',', '')} CT`}</div>
                                    </div>  
                                ))}
                            </div>
                        </div>
        </>)
}