import { FavoriteJobResultsModel } from "../tp-core-types/FavoriteJobResultsModel";
import { HcpUserModel } from "../tp-core-types/HcpUserModel";
import { JobDetailsModel } from "../tp-core-types/JobDetailsModel";
import { JobSearchOptionsModel, Profession } from "../tp-core-types/JobSearchOptionsModel";
import { JobSearchResultsModel } from "../tp-core-types/JobSearchResultsModel";
import { Session } from "../utilities/Session";
import { UserTokenModel } from "../tp-core-types/UserTokenModel";
import { UserInfo } from "../tp-core-types/UserInfoModel";
import { UpdateFavoritesResponse } from "../tp-core-types/UpdateFavoritesResponse";
import { StartApplicationResponse } from "../tp-core-types/StartApplicationResponse";
import { UserHubData } from "../tp-core-types/UserHubData";
import { JobSubmissionResult } from "../tp-core-types/JobSubmissionResult";
import { GetProfessionsResponse } from "../tp-core-types/GetProfessionsResponse";
import { LeadSourcesModel } from "../tp-core-types/LeadSourcesModel";
import { GetLeadSourcesResponse } from "../tp-core-types/GetLeadSourcesResponse";
import { RequestedTimeOff } from "../tp-core-types/RequestedTimeOff";
import { SubmittedJobDetail } from "../tp-core-types/SubmittedJobDetail";
import { ValidationResetPasswordModel } from "../tp-core-types/ValidationResetPasswordModel";
import { FeatureFlagsModel } from "../tp-core-types/FeatureFlagsModel";
import { StatesAndProvinces, StatesAndProvincesEndpointReturn } from "../tp-core-types/PermanentAddressOptions";
import { NavigationSteps } from "../tp-core-types/NavigationSteps";
import { GetHcpCompletedStepsResponse } from "../tp-core-types/GetHcpCompletedStepsResponse";
import { BackgroundAnswers } from "../tp-core-types/BackgroundAnswers";
import { PermanentAddressModel } from "../tp-core-types/PermanentAddress";
import { LicenseInfoModel } from "../tp-core-types/LicenseInfoModel";
import { EmploymentHistory, EmploymentHistoryGetResult, EmploymentHistoryOptionsResult } from "../tp-core-types/EmploymentHistory";
import { EmploymentHistorySaveResult } from "../tp-core-types/EmploymentHistorySaveResult";
import { HcpDegreeTypes } from "../tp-core-types/HcpDegreeTypes";
import { HcpDegreeFields } from "../tp-core-types/HcpDegreeFields";
import { HcpDegree, HcpDegrees } from "../tp-core-types/HcpDegree";
import { HcpDegreeSaveResult } from "../tp-core-types/HcpDegreeSaveResult";
import { HcpWebApplicationCompletedStep } from "../tp-core-types/HcpWebApplicationCompletedStep";
import { ReferenceFormFacilitiesResponse } from "../tp-core-types/ReferenceFormFacilityOption";
import { HcpReferences, ReferenceFormCallbackPayload } from "../tp-core-types/HcpReferences";
import { CertificationTypeResult } from "../tp-core-types/CertificationTypeResult";
import { HcpIdentification } from "../tp-core-types/HcpIdentification";
import { CertificationSaveResult, CertificationsResult } from "../tp-core-types/CertificationsResult";
import { Certification } from "../tp-core-types/Certification";
import blobToBase64 from "../utilities/blobToBase64";
import { CoreCertification } from "../tp-core-types/CoreCertifications";
import { HshChecklistItemsResponse } from "../tp-core-types/HshChecklistItems";
import { HcpProfileData } from "../../src/tp-core-types/HcpProfileData";
import { AssignHcpSkillsChecklistsRequest } from "../tp-core-types/SkillsChecklist";
import { RecentEmploymentHistoryResponse } from "../tp-core-types/RecentEmploymentHistory";
import { ProfileHcpReferenceSaveCommand } from "../tp-core-types/ProfileHcpReferenceSaveCommand";
import { JobOfferDetailsResult } from "../tp-core-types/JobOfferDetailsResult";
import { JobOffersResult } from "../tp-core-types/JobOffersResult";
import { DashboardFacilityDetailsModel } from "../tp-core-types/DashboardFacilityDetailsModel";
import { JobOfferContractDetailsResult } from "../tp-core-types/JobOfferContractDetailsResult";
import { GetSpecialtiesResponse } from "../tp-core-types/GetSpecialtiesResponse";
import { ReferAFriendPayload } from "../tp-core-types/ReferAFriendPayload";
import { ReferralSpecialty } from "../tp-core-types/ReferralSpecialty";

const TPCoreAPI = {
    getJobSearchOptions: async (): Promise<JobSearchOptionsModel> => {
        const data = await fetch(`${process.env.REACT_APP_TRAVEL_PRO_CORE_BASE_URL}/jobsearch/SearchOptions`);

        const json = (await data.json()) as JobSearchOptionsModel;

        json.cities.forEach((x) => (x.id = x.id.toString()));
        json.specialties.forEach((x) => (x.id = x.id.toString()));
        json.states.forEach((x) => (x.id = x.id.toString()));
        json.disciplines.forEach((x) => (x.id = x.id.toString()));

        return json;
    },

    getJobSearchResults: async (
        professionId: string,
        specialtyIds: string | null,
        stateIds: string | null,
        cityIds: string | null,
        shiftCategoryId: string | null,
        numberOfShifts: string | null
    ): Promise<JobSearchResultsModel> => {
        let specialtyIdsPart = "";
        let stateIdsPart = "";
        let cityIdsPart = "";
        let numberOfShiftsPart = "";
        let shiftCategoryPart = "";

        if (specialtyIds) {
            const specialtyIdsString = specialtyIds.replaceAll(",", "&specialtyIds=");
            specialtyIdsPart = `&specialtyIds=${specialtyIdsString}`;
        }

        if (stateIds) {
            const stateIdsString = stateIds.replaceAll(",", "&stateIds=");
            stateIdsPart = `&stateIds=${stateIdsString}`;
        }

        if (cityIds) {
            const cityIdsString = cityIds.replaceAll(",", "&cityIds=");
            cityIdsPart = `&cityIds=${cityIdsString}`;
        }

        shiftCategoryPart = `&shiftCategoryId=${shiftCategoryId ?? ""}`;

        numberOfShiftsPart = `&numberOfShifts=${numberOfShifts ?? ""}`;

        const data = await fetch(
            `${process.env.REACT_APP_TRAVEL_PRO_CORE_BASE_URL}/jobsearch/jobs?disciplineId=${professionId}${specialtyIdsPart}${stateIdsPart}${cityIdsPart}${shiftCategoryPart}${numberOfShiftsPart}`
        );
        return (await data.json()) as JobSearchResultsModel;
    },

    getJobDetails: async (jobId: number): Promise<JobDetailsModel> => {
        let options = {};
        const tokenCheck = Session.getHcpLoginToken();
        if (tokenCheck !== "") {
            options = { headers: { Authorization: `Bearer ${tokenCheck}` } };
        }
        const data = await fetch(
            `${process.env.REACT_APP_TRAVEL_PRO_CORE_BASE_URL}/jobsearch/jobDetails?jobId=${jobId}`,
            options
        );
        return (await data.json()) as JobDetailsModel;
    },

    getJobOfferDetails: async (submissionId: number): Promise<JobOfferDetailsResult> => {
        let options = {};
        const tokenCheck = Session.getHcpLoginToken();
        if (tokenCheck !== "") {
            options = { headers: { Authorization: `Bearer ${tokenCheck}`,"Content-Type": "application/json" } };
        }
        const data = await fetch(
            `${process.env.REACT_APP_TRAVEL_PRO_CORE_BASE_URL}/HcpJobOffers/getJobOfferDetails?submissionId=${submissionId}`,
            options
        );

        if(data.ok) {
            return (await data.json()) as JobOfferDetailsResult;
        }
        return Promise.reject({});
    },

    getJobOfferContractDetails: async (contractId: number): Promise<JobOfferContractDetailsResult> => {
        let options = {};
        const tokenCheck = Session.getHcpLoginToken();
        if (tokenCheck !== "") {
            options = { headers: { Authorization: `Bearer ${tokenCheck}`,"Content-Type": "application/json" } };
        }
        const data = await fetch(
            `${process.env.REACT_APP_TRAVEL_PRO_CORE_BASE_URL}/HcpJobOffers/getContractDetails?contractId=${contractId}`,
            options
        );

        if(data.ok) {
            return (await data.json()) as JobOfferContractDetailsResult;
        }
        return Promise.reject({});
    },

    getJobOffers: async (): Promise<JobOffersResult> => {
        let options = {};
        const tokenCheck = Session.getHcpLoginToken();
        if (tokenCheck !== "") {
            options = { headers: { Authorization: `Bearer ${tokenCheck}` } };
        }
        const data = await fetch(
            `${process.env.REACT_APP_TRAVEL_PRO_CORE_BASE_URL}/HcpJobOffers/getJobOffers`,
            options
        );
        if(data.ok) {
            return (await data.json()) as JobOffersResult;
        }
        return Promise.reject({});
    },

    getUserToken: async (userEmail: string, password: string): Promise<UserTokenModel> => {
        const formBody = [];

        let encodedKey = encodeURIComponent("username");
        let encodedValue = encodeURIComponent(userEmail);
        formBody.push(encodedKey + "=" + encodedValue);
        encodedKey = encodeURIComponent("password");
        encodedValue = encodeURIComponent(password);
        formBody.push(encodedKey + "=" + encodedValue);

        const formBodyString = formBody.join("&");
        const data: Response = await fetch(`${process.env.REACT_APP_TRAVEL_PRO_CORE_BASE_URL}/auth/HcpLogin`, {
            method: "POST",
            body: formBodyString,
            headers: {
                "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
            },
        });
        if (data.ok) {
            const token = await data.text();
            const tokenModel = { token: token } as UserTokenModel;
            return tokenModel;
        }

        return Promise.reject();
    },
    getUserHubData: async (userEmail: string, password: string): Promise<UserHubData> => {
        const loginData = {
            userName: userEmail,
            password: password,
        };
        const data: Response = await fetch(`${process.env.REACT_APP_TRAVEL_PRO_API_BASE_URL}/home/login/website`, {
            method: "POST",
            body: JSON.stringify(loginData),
            headers: {
                "Content-Type": "application/json",
            },
        });
        if (data.ok) {
            const info = await data.json();
            const tokenModel: UserHubData = {
                token: info.authToken,
                firstName: info.UserData.firstName,
                lastName: info.UserData.lastName,
                photoUrl: info.UserData.photoUrl,
            };
            return tokenModel;
        }

        return Promise.reject();
    },
    getCurrentUser: async (token: string): Promise<HcpUserModel> => {
        const fakeHcpUser: HcpUserModel = { hcpId: 23 };
        const fake_user_promise = new Promise<HcpUserModel>((res, rej) => {
            res(fakeHcpUser);
        });
        return fake_user_promise;
    },

    postHcpReferences: async(referenceFormCallbackPayload: ReferenceFormCallbackPayload[]): Promise<Response> => {
        const token: string = Session.getHcpLoginToken()
        
        const options = {
            method: "POST",
            body: JSON.stringify({references: referenceFormCallbackPayload}),
            headers: {
                Authorization: `Bearer ${token}`,
                "Content-Type": "application/json",
            },
        }

        const response = await fetch(`${process.env.REACT_APP_TRAVEL_PRO_CORE_BASE_URL}/HcpJobApplication/HcpReferences`, options)
        return response.ok ? response : Promise.reject({});
    },

    postProfileHcpReference: async(referenceFormCallbackPayload: ProfileHcpReferenceSaveCommand): Promise<Response> => {
        const token: string = Session.getHcpLoginToken()
        
        const options = {
            method: "POST",
            body: JSON.stringify(referenceFormCallbackPayload),
            headers: {
                Authorization: `Bearer ${token}`,
                "Content-Type": "application/json",
            },
        }

        const response = await fetch(`${process.env.REACT_APP_TRAVEL_PRO_CORE_BASE_URL}/HcpReferences/saveReference`, options)
        return response.ok ? response : Promise.reject({});
    },
    
    getHcpReferences: async (): Promise<HcpReferences> => {
        let options = {};
        const tokenCheck = Session.getHcpLoginToken();
        if (tokenCheck !== "") {
            options = { headers: { Authorization: `Bearer ${tokenCheck}`, "Content-Type": "application/json" } };
        }

        const data = await fetch(
            `${process.env.REACT_APP_TRAVEL_PRO_CORE_BASE_URL}/HcpJobApplication/getHcpReferences`,
            options
        );
        return (await data.json()) as HcpReferences;
    },

    postFavoriteJob: async (jobId: number, isFavorited: boolean): Promise<UpdateFavoritesResponse> => {
        const myToken = Session.getHcpLoginToken();
        const options = {
            method: "POST",
            body: JSON.stringify({ jobId: jobId, isFavorited: isFavorited }),
            headers: {
                Authorization: `Bearer ${myToken}`,
                "Content-Type": "application/json",
            },
        };
        const myResponse = await fetch(
            `${process.env.REACT_APP_TRAVEL_PRO_CORE_BASE_URL}/HcpFavoriteJobs/updateFavorite`,
            options
        );
        if (myResponse.ok) {
            return (await myResponse.json()) as UpdateFavoritesResponse;
        } else {
            return Promise.reject();
        }
    },

    postStartApplication: async (
        jobId: number,
        isInterested: boolean,
        desiredStartDate: Date,
        otherRequests: string,
        bestContactTime: string,
        requestedTimeOff: RequestedTimeOff[]
    ): Promise<StartApplicationResponse> => {
        const myToken = Session.getHcpLoginToken();
        const options = {
            method: "POST",
            body: JSON.stringify({
                jobId: jobId,
                isInterested: isInterested,
                desiredStartDate: desiredStartDate,
                otherRequests: otherRequests,
                bestContactTime: bestContactTime,
                requestedTimeOff: requestedTimeOff,
            }),
            headers: {
                Authorization: `Bearer ${myToken}`,
                "Content-Type": "application/json",
            },
        };
        const myResponse = await fetch(
            `${process.env.REACT_APP_TRAVEL_PRO_CORE_BASE_URL}/HcpJobApplication/startJobApplication`,
            options
        );
        if (myResponse.ok) {
            return (await myResponse.json()) as StartApplicationResponse;
        }
        return Promise.reject();
    },

    postHcpSignUp: async (
        email: string,
        phoneNumber: string,
        firstName: string,
        lastName: string,
        professionId: string,
        referralId: string,
        password: string
    ): Promise<UserTokenModel> => {
        const options = {
            method: "POST",
            body: JSON.stringify({
                EmailAddress: email,
                PhoneNumber: phoneNumber,
                FirstName: firstName,
                LastName: lastName,
                ProfessionId: professionId,
                ReferralId: referralId,
                WebPassword: password,
            }),
        };

        const data = await fetch(`${process.env.REACT_APP_TRAVEL_PRO_CORE_BASE_URL}/auth/HcpSignUp`, options);
        if (data.ok) {
            const token = await data.text();
            const tokenModel = { token: token } as UserTokenModel;
            return tokenModel;
        }

        if (data.status === 409) {
            return Promise.reject({ message: "Email already exists. Please login." });
        }

        return Promise.reject({
            message: "Oops... looks like something went wrong. Please try again.",
        });
    },

    getFavoriteJobs: async (): Promise<FavoriteJobResultsModel> => {
        const options = { headers: { Authorization: `Bearer ${Session.getHcpLoginToken()}` } };
        const data = await fetch(
            `${process.env.REACT_APP_TRAVEL_PRO_CORE_BASE_URL}/hcpfavoritejobs/favoriteJobs`,
            options
        );
        return (await data.json()) as FavoriteJobResultsModel;
    },

    saveCertification:  async (certification: Certification, file: File | undefined): Promise<CertificationSaveResult> => {
        let base64Blob;
        
        if(file !== undefined){
            base64Blob = await blobToBase64(file) as string;
        }

        const cert : CoreCertification = {
            "certId" : certification.certId,
            "certificationTypeId" : certification.certificationTypeId,
            "expirationDate" : certification.expirationDate,
            "createdOrder" : certification.createdOrder,
            "attachedFile" : base64Blob ? base64Blob.split('base64,')[1] : undefined,
            "attachedFileType" : file ? file.name.split('.').pop() : undefined,
            "deleted" : certification.deleted
            
        }

        const options = {
            method: "POST",
            headers: {
                Authorization: `Bearer ${Session.getHcpLoginToken()}`,
                "Content-Type": "application/json"
            },
            body: JSON.stringify(cert)
            };

            const data = await fetch(`${process.env.REACT_APP_TRAVEL_PRO_CORE_BASE_URL}/HcpCertification/saveCertification`, options);
            if(data.ok){
                return data.json();
            }
            return Promise.reject({});
        },


        
    getCertifications: async(): Promise<CertificationsResult> => {
        const options = { 
            headers: { Authorization: `Bearer ${Session.getHcpLoginToken()}` ,
            "Content-Type": "application/json"} ,};
        const data = await fetch(
            `${process.env.REACT_APP_TRAVEL_PRO_CORE_BASE_URL}/HcpCertification/hcpCertifications`,
            options
        );

        if(data.ok) {
            const results: CertificationsResult = (await data.json()) as CertificationsResult

            results.certifications.forEach(function (x) {
                x.expirationDate = x.expirationDate ? new Date(new Date(x.expirationDate).toDateString()) : undefined;
                x.saved = true;
            });

            return results;
        } 
        return Promise.reject({})
    }, 

    getCertificationTypes: async (): Promise<CertificationTypeResult> => {
        const options = { 
            headers: { Authorization: `Bearer ${Session.getHcpLoginToken()}` ,
            "Content-Type": "application/json"} ,};
        const data = await fetch(
            `${process.env.REACT_APP_TRAVEL_PRO_CORE_BASE_URL}/HcpCertification/hcpCertificationTypes`,
            options
        );
        if(data.ok){
            return (await data.json()) as CertificationTypeResult;
        }
        return Promise.reject({});
    },

    getUserInfo: async (): Promise<UserInfo> => {
        const hcpLoginToken = Session.getHcpLoginToken();
        const options = { headers: { Authorization: `Bearer ${hcpLoginToken}` } };
        const data = await fetch(`${process.env.REACT_APP_TRAVEL_PRO_CORE_BASE_URL}/HcpApiUser/userInfo`, options);

        if (data.ok) {
            return (await data.json()) as UserInfo;
        }
        return Promise.reject({
            firstName: "",
            lastName: "",
            applicationComplete: false,
        });
    },

    getSubmittedJobDetails: async (jobId: number): Promise<SubmittedJobDetail> => {
        const options = { headers: { Authorization: `Bearer ${Session.getHcpLoginToken()}` } };
        const data = await fetch(
            `${process.env.REACT_APP_TRAVEL_PRO_CORE_BASE_URL}/HcpSubmittedJob/submittedJobDetails?jobId=${jobId}`,
            options
        );
        const submittedJobDetail = (await data.json()) as SubmittedJobDetail;
        submittedJobDetail.requestedTimeOff.forEach(function (x) {
            x.fromDate = x.fromDate ? new Date(x.fromDate) : null;
            x.toDate = x.toDate ? new Date(x.toDate) : null;
        });
        return submittedJobDetail;
    },

    getSubmittedJobs: async (): Promise<JobSubmissionResult> => {
        const hcpLoginToken = Session.getHcpLoginToken();
        const options = { headers: { Authorization: `Bearer ${hcpLoginToken}` } };
        const data = await fetch(
            `${process.env.REACT_APP_TRAVEL_PRO_CORE_BASE_URL}/HcpSubmittedJob/submittedJobs`,
            options
        );
        return (await data.json()) as JobSubmissionResult;
    },
    getSpecialties: async(): Promise<ReferralSpecialty[]> => {
        const response = await fetch(`${process.env.REACT_APP_TRAVEL_PRO_CORE_BASE_URL}/Specialty/getSpecialties`);
        if(response.ok) {
            return ((await response.json())).specialties;
        }
        return Promise.reject([]);
    },
    getProfessions: async (): Promise<Profession[]> => {
        const response = await fetch(`${process.env.REACT_APP_TRAVEL_PRO_CORE_BASE_URL}/Disciplines`);

        if (response.ok) {
            return ((await response.json()) as GetProfessionsResponse).disciplines;
        }

        return Promise.reject([]);
    },

    getLeadSourceResults: async (): Promise<LeadSourcesModel[]> => {
        const response = await fetch(`${process.env.REACT_APP_TRAVEL_PRO_CORE_BASE_URL}/leadSources`);
        if (response.ok) {
            return ((await response.json()) as GetLeadSourcesResponse).leadSources;
        }
        return Promise.reject([]);
    },

    getFeatureFlags: async (): Promise<FeatureFlagsModel> => {
        const response = await fetch(`${process.env.REACT_APP_TRAVEL_PRO_CORE_BASE_URL}/FeatureFlags`);
        if (response.ok) {
            return (await response.json()) as FeatureFlagsModel;
        } else {
            return Promise.reject();
        }
    },

    sendPasswordReset: async (webUserName: string) => {
        const formBody = [];
        const encodedKey = encodeURIComponent("webUserName");
        const encodedValue = encodeURIComponent(webUserName);
        formBody.push(encodedKey + "=" + encodedValue);
        const formBodyString = formBody.join("&");
        const options = {
            method: "POST",
            body: formBodyString,
            headers: {
                "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
            },
        };

        await fetch(`${process.env.REACT_APP_TRAVEL_PRO_CORE_BASE_URL}/auth/ResetPasswordRequest`, options);
    },
    validateResetGuid: async (resetGuid: string): Promise<ValidationResetPasswordModel> => {
        const formBody = [];
        const encodedKey = encodeURIComponent("resetGuid");
        const encodedValue = encodeURIComponent(resetGuid);
        formBody.push(encodedKey + "=" + encodedValue);
        const formBodyString = formBody.join("&");
        const options = {
            method: "POST",
            body: formBodyString,
            headers: {
                "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
            },
        };

        const data = await fetch(`${process.env.REACT_APP_TRAVEL_PRO_CORE_BASE_URL}/auth/ValidateResetGuid`, options);
        return (await data.json()) as ValidationResetPasswordModel;
    },
    resetPassword: async (resetGuid: string, newPassword: string) => {
        const formBody = [];
        let encodedKey = encodeURIComponent("resetGuid");
        let encodedValue = encodeURIComponent(resetGuid);
        formBody.push(encodedKey + "=" + encodedValue);

        encodedKey = encodeURIComponent("newPassword");
        encodedValue = encodeURIComponent(newPassword);
        formBody.push(encodedKey + "=" + encodedValue);
        const formBodyString = formBody.join("&");

        const options = {
            method: "POST",
            body: formBodyString,
            headers: {
                "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
            },
        };

        await fetch(`${process.env.REACT_APP_TRAVEL_PRO_CORE_BASE_URL}/auth/UpdateHcpUserPassword`, options);
    },
    getStatesAndProvinces: async (): Promise<StatesAndProvinces> => {
        const hcpLoginToken = Session.getHcpLoginToken();
        const options = {
            headers: {
                Authorization: `Bearer ${hcpLoginToken}`,
                "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
            },
        };

        const data = await fetch(
            `${process.env.REACT_APP_TRAVEL_PRO_CORE_BASE_URL}/HcpPermanentAddress/permanentAddressStates`,
            options
        );

        const statesAndProvinces = (await data.json()) as StatesAndProvincesEndpointReturn;
        return statesAndProvinces.statesAndProvinces;
    },

    getCompletedHCPApplicationSteps: async (): Promise<NavigationSteps[]> => {
        const hcpLoginToken = Session.getHcpLoginToken();
        const options = {
            headers: {
                Authorization: `Bearer ${hcpLoginToken}`,
                "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
            },
        };

        const data = await fetch(
            `${process.env.REACT_APP_TRAVEL_PRO_CORE_BASE_URL}/HcpJobApplication/getCompletedHCPApplicationSteps`,
            options
        );
        const navigationSteps = (await data.json()) as GetHcpCompletedStepsResponse;
        const retSteps = navigationSteps.completedSteps.map(
            (strStepName) => NavigationSteps[strStepName as keyof typeof NavigationSteps]
        );
        return retSteps;
    },

    getHcpBackgroundAnswers: async (): Promise<BackgroundAnswers> => {
        const hcpLoginToken = Session.getHcpLoginToken();
        const options = {
            headers: {
                Authorization: `Bearer ${hcpLoginToken}`,
                "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
            },
        };

        const response = await fetch(
            `${process.env.REACT_APP_TRAVEL_PRO_CORE_BASE_URL}/HcpBackground/hcpBackgroundAnswers`,
            options
        );

        if (response.ok) {
            const json = await response.json();
            return new BackgroundAnswers(json);
        }

        return Promise.reject({});
    },

    postHcpBackgroundAnswers: async (backgroundAnswers: BackgroundAnswers): Promise<Record<string, never>> => {
        const hcpLoginToken = Session.getHcpLoginToken();
        const options = {
            method: "POST",
            body: JSON.stringify(backgroundAnswers),
            headers: {
                Authorization: `Bearer ${hcpLoginToken}`,
                "Content-Type": "application/json",
            },
        };

        const response = await fetch(
            `${process.env.REACT_APP_TRAVEL_PRO_CORE_BASE_URL}/HcpBackground/hcpBackgroundAnswers`,
            options
        );

        if (response.ok) {
            return await response.json();
        }

        return Promise.reject();
    },

    getUpdateBackgroundAnswers: async (): Promise<BackgroundAnswers> => {
        const hcpLoginToken = Session.getHcpLoginToken();
        const options = {
            headers: {
                Authorization: `Bearer ${hcpLoginToken}`,
                "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
            },
        };

        const response = await fetch(
            `${process.env.REACT_APP_TRAVEL_PRO_CORE_BASE_URL}/BackgroundQuestions/getBackgroundAnswers`,
            options
        );

        if (response.ok) {
            const json = await response.json();
            return new BackgroundAnswers(json);
        }

        return Promise.reject({});
    },

    postUpdateBackgroundAnswers: async (backgroundAnswers: BackgroundAnswers): Promise<Record<string, never>> => {
        const hcpLoginToken = Session.getHcpLoginToken();
        const options = {
            method: "POST",
            body: JSON.stringify(backgroundAnswers),
            headers: {
                Authorization: `Bearer ${hcpLoginToken}`,
                "Content-Type": "application/json",
            },
        };

        const response = await fetch(
            `${process.env.REACT_APP_TRAVEL_PRO_CORE_BASE_URL}/BackgroundQuestions/postBackgroundAnswers`,
            options
        );

        if (response.ok) {
            return await response.json();
        }

        return Promise.reject();
    },



    postHcpPermenentAddress: async (permananentAddress: PermanentAddressModel) => {
        const hcpLoginToken = Session.getHcpLoginToken();
        const options = {
            method: "POST",
            body: JSON.stringify(permananentAddress),
            headers: {
                Authorization: `Bearer ${hcpLoginToken}`,
                "Content-Type": "application/json",
            },
        };
        return await fetch(
            `${process.env.REACT_APP_TRAVEL_PRO_CORE_BASE_URL}/HcpPermanentAddress/permanentAddress`,
            options
        );
    },

    postLicenseInfo: async (licenseInfo: LicenseInfoModel): Promise<Response> => {
        const hcpLoginToken = Session.getHcpLoginToken();
        const options = {
            method: "POST",
            body: JSON.stringify(licenseInfo),
            headers: {
                Authorization: `Bearer ${hcpLoginToken}`,
                "Content-Type": "application/json",
            },
        };
        const response = await fetch(
            `${process.env.REACT_APP_TRAVEL_PRO_CORE_BASE_URL}/HcpLicenseSearchInfo`,
            options
        );

        return response.ok ? response : Promise.reject({});
    },

    getLicenseInfo: async(): Promise<LicenseInfoModel> => {
        const token = Session.getHcpLoginToken();
        const options = {
            headers: {
                Authorization: `Bearer ${token}`,
                "Content-Type": "application/json"
            },
        };
        const response = await fetch(
            `${process.env.REACT_APP_TRAVEL_PRO_CORE_BASE_URL}/HcpLicenseSearchInfo`,
            options
        );
        return response.ok ? await response.json() : Promise.reject({});
    },

    getEmploymentHistory: async (): Promise<EmploymentHistoryGetResult> => {
        const token = Session.getHcpLoginToken();
        const options = {
            headers: {
                Authorization: `Bearer ${token}`,
                "Content-Type": "application/json"
            },
        };
        const response = await fetch(
            `${process.env.REACT_APP_TRAVEL_PRO_CORE_BASE_URL}/HcpEmploymentHistory/getEmploymentHistoryRecords`,
            options
        );
        return response.ok ? await response.json() : Promise.reject({});
    },

    postEmploymentHistory: async (employmenthistory: EmploymentHistory): Promise<EmploymentHistorySaveResult> => {
        const hcpLoginToken = Session.getHcpLoginToken();
        const options = {
            method: "POST",
            body: JSON.stringify(employmenthistory),
            headers: {
                Authorization: `Bearer ${hcpLoginToken}`,
                "Content-Type": "application/json",
            },
        };


        const response = await fetch(
            `${process.env.REACT_APP_TRAVEL_PRO_CORE_BASE_URL}/HcpEmploymentHistory/insert`,
            options
        );

        return response.ok ? await response.json() : Promise.reject({});
    },

    postProfileHcpEmploymentHistory: async(employmentHistory: EmploymentHistory): Promise<EmploymentHistorySaveResult> => {
        const hcpLoginToken = Session.getHcpLoginToken();
        const options = {
            method: "POST",
            body: JSON.stringify(employmentHistory),
            headers: {
                Authorization:  `Bearer ${hcpLoginToken}`,
                "Content-Type": "application/json"
            }
        }

        const response = await fetch(
            `${process.env.REACT_APP_TRAVEL_PRO_CORE_BASE_URL}/HcpProfileEmploymentHistory/saveEmploymentHistory`, 
            options
        )

        return response.ok ? await response.json() : Promise.reject({});
    },

    updateEmploymentHistory: async(employmentHistory: EmploymentHistory): Promise<EmploymentHistorySaveResult | Record<string, never>> => {
        const hcpLoginToken = Session.getHcpLoginToken();
        const options = {
            method: "PUT",
            body: JSON.stringify(employmentHistory),
            headers: {
                Authorization: `Bearer ${hcpLoginToken}`,
                "Content-Type": "application/json",
            },
        };

        const response = await fetch(
            `${process.env.REACT_APP_TRAVEL_PRO_CORE_BASE_URL}/HcpEmploymentHistory/update`,
            options
        );

        return response.ok ? await response.json() : Promise.reject({});
    },

    deleteEmploymentHistory: async (employmenthistoryId: number): Promise<void> => {
        const hcpLoginToken = Session.getHcpLoginToken();
        const options = {
            method: "DELETE",
            body: JSON.stringify({EmploymentHistoryId: employmenthistoryId}),
            headers: {
                Authorization: `Bearer ${hcpLoginToken}`,
                "Content-Type": "application/json",
            },
        };
        const response = await fetch(
            `${process.env.REACT_APP_TRAVEL_PRO_CORE_BASE_URL}/HcpEmploymentHistory/delete`,
            options
        );

        return response.ok ? Promise.resolve() : Promise.reject({});
    },

    getEmploymentHistoryOptions: async (): Promise<EmploymentHistoryOptionsResult> => {
        const hcpLoginToken = Session.getHcpLoginToken();
        const options = {
            headers: {
                Authorization: `Bearer ${hcpLoginToken}`,
                "Content-Type": "application/json",
            },
        };

        const response = await fetch(
            `${process.env.REACT_APP_TRAVEL_PRO_CORE_BASE_URL}/HcpEmploymentHistory/optionsv2`,
            options
        );

        return response.ok ? await response.json() : Promise.reject({});
    },

    getHcpDegreeTypes: async (): Promise<HcpDegreeTypes> => {
        const hcpLoginToken = Session.getHcpLoginToken();
        const options = {
            headers: {
                Authorization: `Bearer ${hcpLoginToken}`,
                "Content-Type": "application/json",
            },
        };

        const response = await fetch(
            `${process.env.REACT_APP_TRAVEL_PRO_CORE_BASE_URL}/HcpJobApplication/getDegreeTypes`,
            options
        );

        return response.ok ? ((await response.json()) as HcpDegreeTypes) : Promise.reject({});
    },

    getHcpDegreeFields: async (): Promise<HcpDegreeFields> => {
        const hcpLoginToken = Session.getHcpLoginToken();
        const options = {
            headers: {
                Authorization: `Bearer ${hcpLoginToken}`,
                "Content-Type": "application/json",
            },
        };

        const response = await fetch(
            `${process.env.REACT_APP_TRAVEL_PRO_CORE_BASE_URL}/HcpJobApplication/getDegreeFields`,
            options
        );

        return response.ok ? ((await response.json()) as HcpDegreeFields) : Promise.reject({});
    },

    postHcpDegree: async (hcpDegree: HcpDegree): Promise<HcpDegreeSaveResult> => {
        const hcpLoginToken = Session.getHcpLoginToken();
        const options = {
            method: "POST",
            body: JSON.stringify(hcpDegree),
            headers: {
                Authorization: `Bearer ${hcpLoginToken}`,
                "Content-Type": "application/json"
            }
        }
        const response = await fetch(
            `${process.env.REACT_APP_TRAVEL_PRO_CORE_BASE_URL}/HcpDegree/saveHcpDegree`,
            options
        );

        return await response.ok ? (response.json()) : Promise.reject({});
    },
    
    postWebApplicationStep: async (completedStep: HcpWebApplicationCompletedStep): Promise<void> => {
        const hcpLoginToken = Session.getHcpLoginToken();
        const options = {
            method: "POST",
            body: JSON.stringify(completedStep),
            headers: {
                Authorization: `Bearer ${hcpLoginToken}`,
                "Content-Type": "application/json"
            }
        }
        const response = await fetch(
            `${process.env.REACT_APP_TRAVEL_PRO_CORE_BASE_URL}/HcpWebApplicationStep/updateWebApplicationStep`,
            options
        );

        return response.ok ? Promise.resolve() : Promise.reject({});
    },

    getHcpDegrees: async (): Promise<HcpDegrees> =>
    {
        const hcpLoginToken = Session.getHcpLoginToken();
        const options = {
            headers: {
                Authorization: `Bearer ${hcpLoginToken}`,
                "Content-Type": "application/json",
            },
        };

        const response = await fetch(
            `${process.env.REACT_APP_TRAVEL_PRO_CORE_BASE_URL}/HcpDegree/getHcpDegrees`,
            options
        );

        return response.ok ? ((await response.json()) as HcpDegrees) : Promise.reject({});
    },
    postHcpIdentification: async(obj: HcpIdentification): Promise<Response> => {
        const hcpLoginToken = Session.getHcpLoginToken();
        const options = {
            method: "POST",
            body: JSON.stringify(obj),
            headers: {
                Authorization: `Bearer ${hcpLoginToken}`,
                "Content-Type": "application/json"
            }
        }
        const response = await fetch(
            `${process.env.REACT_APP_TRAVEL_PRO_CORE_BASE_URL}/HcpIdentification/saveHcpIdentification`,
            options
        );

        return response.ok ? response : Promise.reject({});
    },
    postReferralData: async(obj: ReferAFriendPayload): Promise<Response> => {
        const hcpLoginToken = Session.getHcpLoginToken();
        const options = {
            method: "POST",
            body: JSON.stringify(obj),
            headers: {
                Authorization: `Bearer ${hcpLoginToken}`,
                "Content-Type": "application/json"
            }
        }
        const response = await fetch(
            `${process.env.REACT_APP_TRAVEL_PRO_CORE_BASE_URL}/HcpReferral/saveReferral`,
            options
        )
        return response.ok ? response : Promise.reject({
            message: "Oops... looks like something went wrong. Please try again.",
        });
    },
    getRecentEmploymentHistory: async (): Promise<RecentEmploymentHistoryResponse> => {
        const hcpLoginToken = Session.getHcpLoginToken();
        const options = {
            method: "GET",
            headers: {
                Authorization: `Bearer ${hcpLoginToken}`,
                "Content-Type": "application/json"
            }
        }
        const response = await fetch(
            `${process.env.REACT_APP_TRAVEL_PRO_CORE_BASE_URL}/HcpEmploymentHistory/recentEmploymentHistory`,
            options
        );
        
        return await  response.ok ? (response.json()) : Promise.reject({});
    },

    getHcpAddress: async (): Promise<PermanentAddressModel> => {
        const hcpLoginToken = Session.getHcpLoginToken();
        const options = {
            headers: {
                Authorization: `Bearer ${hcpLoginToken}`,
                "Content-Type": "application/json",
            },
        };

        const response = await fetch(
            `${process.env.REACT_APP_TRAVEL_PRO_CORE_BASE_URL}/HcpPermanentAddress/getHcpPermanentAddress`,
            options
        );

        return response.ok ? ((await response.json()) as PermanentAddressModel) : Promise.reject({});
    },

    saveSubmitApplication: async (): Promise<Response> => {
        const hcpLoginToken = Session.getHcpLoginToken();
        const options = {
            method: "POST",
            body: JSON.stringify({}),
            headers: {
                Authorization: `Bearer ${hcpLoginToken}`,
                "Content-Type": "application/json"
            }
        }

        const response = await fetch(
            `${process.env.REACT_APP_TRAVEL_PRO_CORE_BASE_URL}/SubmitApplication/saveSubmitApplication`,
            options
        );

        return response.ok? (await response) : Promise.reject({});
    },

    getHshChecklistItems: async (): Promise<HshChecklistItemsResponse> => {
        const hcpLoginToken = Session.getHcpLoginToken();
        const options = {
            headers: {
                Authorization: `Bearer ${hcpLoginToken}`,
                "Content-Type": "application/json",
            },
        };

        const response = await fetch(
            `${process.env.REACT_APP_TRAVEL_PRO_CORE_BASE_URL}/HcpSkills/getHcpHshItems`,
            options
        );

        return response.ok? (await response.json()) : Promise.reject({});
    },

    getHcpProfileData: async (): Promise<HcpProfileData> => {
        const hcpLoginToken = Session.getHcpLoginToken();
        const options = {
            headers: {
                Authorization: `Bearer ${hcpLoginToken}`,
                "Content-Type": "application/json",
            },
        };

        const response = await fetch(
            `${process.env.REACT_APP_TRAVEL_PRO_CORE_BASE_URL}/HcpProfileData/getProfile`,
            options
        );

        return response.ok? (await response.json()) : Promise.reject({});
    },

    getFacilityOptions: async(): Promise<ReferenceFormFacilitiesResponse> => {
        const token = Session.getHcpLoginToken();
        const options = {
            headers: {
                Authorization: `Bearer ${token}`,
                "Content-Type": "application/json"
            },
        };
        const response = await fetch(
            `${process.env.REACT_APP_TRAVEL_PRO_CORE_BASE_URL}/HcpReferences/getFacilityOptions`,
            options
        );

        return response.ok ? await response.json() : Promise.reject({});
    },

    assignHcpSkillsChecklists: async (request: AssignHcpSkillsChecklistsRequest): Promise<Response> => {
        const hcpLoginToken = Session.getHcpLoginToken();
        const options = {
            method: "POST",
            body: JSON.stringify(request),
            headers: {
                Authorization: `Bearer ${hcpLoginToken}`,
                "Content-Type": "application/json"
            }
        };
        const response = await fetch(
            `${process.env.REACT_APP_TRAVEL_PRO_CORE_BASE_URL}/HcpSkills/assignSkillsChecklists`,
            options
        );

        return response.ok? response : Promise.reject({});       
    },

    getHcpProfileOffers: async (): Promise<DashboardFacilityDetailsModel> => {
        const token = Session.getHcpLoginToken();
        const options = {
            headers: {
                Authorization: `Bearer ${token}`,
                "Content-Type": "application/json"
            },
        };
        const response = await fetch(
            `${process.env.REACT_APP_TRAVEL_PRO_CORE_BASE_URL}/getHcpSubmittedOffers`,
            options
        );

        return response.ok ? await response.json() : Promise.reject({});
    },
};

export default TPCoreAPI;
