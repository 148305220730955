import GenericPageHeader from "../generic-page-header/GenericPageHeader";
import "./CredentialsDropbox.scss"
import plus_sign from "../assets/plus_sign_white.svg";
import { useEffect, useRef, useState } from "react";
import { DropboxItem } from "../tp-core-types/DropboxDocumentsResult";
import TPCoreAPIDocuments from "../tp-core-api-common/TPCoreAPIDocuments";
import { DropboxUpload, DropboxUploadResult } from "../tp-core-types/DropboxUpload";
import { DropboxAttach } from "../tp-core-types/DropboxAttach";
import DropboxDocument from "./DropboxItem";
import LoadingOverlay from "../../src/loading-overlay/LoadingOverlay"
import { useError } from "../utilities/context/useError";
import DragNDrop from "./DragNDrop";



export default function CredentialsDropbox() {
    const { setError } = useError();
    const acceptableTypes: string[] = ["pdf", "doc", "docx", "jpg", "jpeg", "png", "gif", "bmp", "jfif"];
    const [dropboxItems, setDropboxItems] = useState<DropboxItem[]>([] as DropboxItem[]);
    const [file, setFile] = useState<DropboxUploadResult | undefined>();
    const [uploadedFileName, setUploadedFileName] = useState<string>("");
    const [showAddDocumentModal, setShowAddDocumentModal] = useState<boolean>(false);
    const [newDocumentLabel, setNewDocumentLabel] = useState<string>("");
    const [ uploadDisabled, setUploadDisabled ] = useState<boolean>(true);

    useEffect(() => {
        fetchDocuments()
    }, [])

    const fetchDocuments = async () => {
        const res = await TPCoreAPIDocuments.getDropboxDocuments();
        setDropboxItems(res.dropboxItems)
    }

    const handleAddDocument = async () => {
        setNewDocumentLabel("");
        setUploadDisabled(true);
        const attachment = {
            filepath: file?.filePath,
            documentTitle: newDocumentLabel
        } as DropboxAttach;
        
        await TPCoreAPIDocuments.attachDropboxDocument(attachment);
        fetchDocuments();
        setShowAddDocumentModal(!showAddDocumentModal);
    }

    const initiateFileUpload  = () => {
        const btn = document.getElementById("file-picker");
        btn?.click();
    }

    const handleFileInputChange = async (file: File | null) => {

        if( file ) {
            const fileType = file?.name.split('.').pop() ?? "";
            if(acceptableTypes.includes(fileType)) {
                setShowAddDocumentModal(!showAddDocumentModal)
                const upload = {
                    file: file,
                    fileType: fileType
                } as DropboxUpload
               
                const result = await TPCoreAPIDocuments.uploadDropboxDocument(upload)
                setFile(result);
                setUploadedFileName(file.name)
            }
            else {
                setError(new Error(`Invalid file type: .${fileType}`))
            }
        }        
    }
    const handleChangeFile = () => {
        setShowAddDocumentModal(false);
        setNewDocumentLabel("");
        setUploadDisabled(true);
        initiateFileUpload();
    }

    const deleteDocument = async (hcpDropboxId: number) => {
        const newItems = dropboxItems.filter((item) => item.hcpDropBoxId !== hcpDropboxId);
        setDropboxItems(newItems);
        await TPCoreAPIDocuments.deleteDropboxDocument(hcpDropboxId);
        fetchDocuments();
    }

    async function openFile(filePath: string){
        let waitingPage;
        try{
            waitingPage = window.open("document-loading.html")!;
            const myData= (await TPCoreAPIDocuments.getDocumentAttachment(filePath));
            const file = window.URL.createObjectURL(myData);
            waitingPage.location.href = file;
        }
        catch{
            setError(new Error("Something went wrong when opening the file."))
            waitingPage?.close();
        }
    }

    return (

    <div id="CredentialsDropbox">
         { showAddDocumentModal &&
            <div className="modal-container-background">
                <div data-testid="upload-modal" className="upload-modal">
                <div className="modal-header">Name Your Document</div>
                    {file 
                    ? 
                        <div className="modal-content">
                            <div className="modal-document-preview">
                                <div className="document-display">
                                    {
                                        file?.images 
                                        ? 
                                            <>
                                                {file.images.map((img) => <img className="doc-preview-item" src={img} alt="document-preview"/>)}
                                            </>
                                        : 
                                            <></>
                                    }
                                </div>
                                <div className="document-preview-filename">{uploadedFileName}</div>
                            </div>
                            <div className="modal-input-container">
                                <div className="modal-inputs">
                                    <label htmlFor="input-docname">Document Label:</label>
                                    <input id="input-docname" placeholder="Name your document" onChange={(e) => {setNewDocumentLabel(e.target.value); setUploadDisabled(e.target.value.length === 0) }} value={newDocumentLabel}/>
                                </div>
                                <div className="modal-button-container">
                                    <button data-testid="change-file-button" className="modal-change-button" onClick={handleChangeFile}>Choose Different File</button>
                                    <button data-testid="upload-file-button" className="modal-upload-button" disabled={ uploadDisabled } onClick={() => handleAddDocument()}>Upload Document</button>
                                </div>
                            </div>
                        </div>
                    : <LoadingOverlay/>}
                    
                </div>
            </div>  
        }
        <GenericPageHeader title="Credentials Dropbox"></GenericPageHeader>
        <div className="page-container">
           
            <div className="upload-document-container">
                <div className="sub-title">Drag and drop documents into the area below or upload from device</div>
                <button className="upload-button">
                    <img alt="plus-sign" src={plus_sign}></img>
                    <div onClick={() => initiateFileUpload()}>Upload Document</div>
                </button>
                    <DragNDrop onFilesSelected={handleFileInputChange}/>
            </div>
            <div className="uploaded-documents-container">
                <div className="sub-title">Uploaded Documents</div>
                <div data-testid="uploaded-documents" className="uploaded-documents">
                    {
                        dropboxItems.map(d => {
                            return <DropboxDocument key={d.hcpDropBoxId} document={d} deleteDocument={deleteDocument} openDocument={openFile}/>
                        })
                    }
                </div>
            </div>
        </div>
    </div>
    )
}