import { ReactNode } from "react";
import FeatureFlagContext from "../../tp-core-types/FeatureFlagsModel";

export function FeatureFlagContextProvider({ children }: { children: ReactNode }) {
    const defaultFeatureFlag = {
        featureFlag: {
            featureFlag_HcpApplication_SkillsChecklist_IsEnabled: process.env.REACT_APP_FEATUREFLAG_HCPAPPLICATION_SKILLSCHECKLIST_ISENABLED === "true",
            featureFlag_DashboardOffersAndSubmissions_IsEnabled: process.env.REACT_APP_FEATUREFLAG_DASHBOARDOFFERSANDSUBMISSIONS_ISENABLED === "true",
            featureFlag_DocumentsAndSignatures_IsEnabled: process.env.REACT_APP_FEATUREFLAG_DOCUMENTSANDSIGNATURES_ISENABLED === "true",
            featureFlag_ReferAFriend_IsEnabled: process.env.REACT_APP_FEATUREFLAG_REFERAFRIEND_ISENABLED === "true",
            featureFlag_CredentialsDropbox_IsEnabled: process.env.REACT_APP_FEATUREFLAG_CREDENTIALSDROPBOX_ISENABLED === "true",
            featureFlag_UpdateBackgroundQuestions_IsEnabled: process.env.REACT_APP_FEATUREFLAG_UPDATEBACKGROUNQUESTIONS_ISENABLED === "true"

        },
    };

    return <FeatureFlagContext.Provider value={defaultFeatureFlag}>{children}</FeatureFlagContext.Provider>;
}
